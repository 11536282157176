import React from "react";
import "./src/components/global/global.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdSIwEeAAAAAE6niSkaUg8VpRMPhKot-HGhc_6t">
      {element}
    </GoogleReCaptchaProvider>
  );
};
